import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import bannerPic from '../../assets/images/stunners.png';
import Header from '../../components/Header';

import homeScreenshot from '../../assets/images/stunners-home.png';
import appointmentsScreenshot from '../../assets/images/stunners-appointments.png';

const Stunners = (props) => (
    <Layout>
        <div id="stunners">
            <Header></Header>
            <Helmet>
                <title>Dave Martin Programming - Stunners</title>
                <meta name="description" content="Dave Martin Programming Stunners mobile expo react native" />
            </Helmet>

            <section id="banner" className="light" style={{ backgroundImage: `url(${bannerPic})` }}>
                <div className="inner">
                    <header className="major">
                        <h1>Stunners</h1>
                    </header>
                    <div className="content">
                        <p>
                            A native mobile app for booking freelance stylists
                        </p>
                    </div>
                </div>
            </section>

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <p>
                            Stunners is a mobile app for connecting freelance stylists with clients. 
                            Unfortunately, it was never published to the app store, but the app was fully functional. 
                            Source code is available on request.
                        </p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <img src={homeScreenshot} className="image" alt="" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Front End</h3>
                                </header>
                                <p>I built the Stunners mobile app using React Native, Expo, ClojureScript, and Re-Frame.</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <img src={appointmentsScreenshot} className="image" />
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Back End</h3>
                                </header>
                                <p>
                                    The mobile app is backed by an HTTP API backend. I wrote this in Clojure, leveraging the Datomic database.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    </Layout>
)

export default Stunners;